import { ENTITLEMENT_META } from "../reclaim-api/team/Team.consts";
import { EntitlementType, EntitlementValueTypeMap, ReclaimEdition } from "../reclaim-api/team/Team.types";
import { EntitlementActualObject, ProductUsageReportActuals } from "../reclaim-api/Users.types";
import { typedEntries } from "./objects";

export const getEntitlementActualObjectUsageEdition = <E extends EntitlementType>(
  entitlement: E,
  usage: EntitlementActualObject<EntitlementValueTypeMap[E]>
): ReclaimEdition | undefined => {
  // if no actualValue is present assume no overage
  if (usage.actualValue === undefined) return undefined;
  const { comparator } = ENTITLEMENT_META[entitlement];
  return comparator(usage.allowedValueForCurrentEdition as never, usage.actualValue as never) < 0
    ? usage.requiredEdition
    : undefined;
};

export const getProductUsageReportActualsUsageEdition = (
  usage: ProductUsageReportActuals
): ReclaimEdition | undefined => {
  let usageEdition: ReclaimEdition | undefined;

  typedEntries(usage).find(([entitlement, obj]) => {
    const foundEdition = obj && getEntitlementActualObjectUsageEdition(entitlement, obj);
    if (foundEdition) usageEdition = foundEdition;
    return !!usageEdition;
  });

  return usageEdition;
};

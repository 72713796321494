
// eslint-disable-next-line no-restricted-imports
import { Menu as MuiMenu, MenuProps } from "@mui/material";
import { VFC } from "react";

export const Menu: VFC<MenuProps> = ({ ...rest }) => {
  return (
    <MuiMenu
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...rest}
    />
  );
};

import { makeOrderedListComparator } from "../../utils/sort";
import { MembershipRole } from "./Team";
import { EDITION_ORDER, ENTITLEMENT_META } from "./Team.consts";
import { EntitlementType, EntitlementValueObject, EntitlementValueTypeMap, ReclaimEdition } from "./Team.types";

export const reclaimEditionComparator = makeOrderedListComparator(EDITION_ORDER);
export const membershipRoleComparator = makeOrderedListComparator([MembershipRole.Admin, MembershipRole.User]);

export const canChangeSettings = (role: MembershipRole | undefined): boolean => role === MembershipRole.Admin;
export const canInvite = (role: MembershipRole | undefined, userInviteLevel: ReclaimEdition | undefined): boolean =>
  canChangeSettings(role) || userInviteLevel !== "NONE";

export const entitlementValueObjectWithOverride = <ENTITLEMENT extends EntitlementType>(
  entitlement: ENTITLEMENT,
  edition: ReclaimEdition,
  obj: EntitlementValueObject<EntitlementValueTypeMap[ENTITLEMENT]> | undefined
): EntitlementValueObject<EntitlementValueTypeMap[ENTITLEMENT]> | undefined =>
  obj &&
  ({
    ...obj,
    value:
      ENTITLEMENT_META[entitlement].displayOverrideValues?.[edition] === undefined
        ? obj.value
        : ENTITLEMENT_META[entitlement].displayOverrideValues?.[edition],
  } as EntitlementValueObject<EntitlementValueTypeMap[ENTITLEMENT]>);

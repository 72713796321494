import { ClassNameMap } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  DateCalendar as MuiDateCalendar,
  DateCalendarProps as MuiDateCalendarProps,
} from "@mui/x-date-pickers/DateCalendar";
import clsx from "clsx";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "auto",
      "& .MuiPickersCalendarHeader-root": {
        margin: theme.spacing(1, 0, 0),
        padding: theme.spacing(0, 0, 0, 1),
      },
      "& .MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition": {
        minHeight: 230,
      },
    },
    day: {
      color: theme.palette.primary.main,
      borderRadius: 999999999,
      fontWeight: theme.typography.fontWeightMedium,
      //today
      "&.MuiPickersDay-today": {
        border: 0,
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightBold,
      },
      //selected
      "&.Mui-selected": {
        color: theme.colors.white,
        fontWeight: theme.typography.fontWeightMedium,
      },
      //disabled
      "&.Mui-disabled": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
  }),
  {
    classNamePrefix: "DateCalendar",
  }
);

export type DateCalendarJSSClassKey = keyof ReturnType<typeof useStyles>;

export type DateCalendarProps<T_DATE> = Omit<MuiDateCalendarProps<T_DATE>, "classes"> & {
  classes?: Partial<ClassNameMap<DateCalendarJSSClassKey>>;
  MuiDateCalendarClasses?: MuiDateCalendarProps<T_DATE>["classes"];
};

export const DateCalendar = <T_DATE extends unknown>({
  className,
  classes: extClasses,
  MuiDateCalendarClasses,
  slotProps,
  views = ["day"],
  ...rest
}: DateCalendarProps<T_DATE>) => {
  const classes = useStyles({
    classes: extClasses,
  });

  /********************/
  /*   custom hooks   */
  /********************/

  /********************/
  /*     useState     */
  /********************/

  /********************/
  /* useMemo & consts */
  /********************/

  /********************/
  /*    useCallback   */
  /********************/

  /********************/
  /*    useEffects    */
  /********************/

  /********************/
  /*       JSX        */
  /********************/

  return (
    <MuiDateCalendar
      className={clsx(classes.root, className)}
      classes={MuiDateCalendarClasses}
      slotProps={{
        ...slotProps,
        day: {
          ...slotProps?.day,
          classes: {
            root: classes.day,
          },
        },
      }}
      views={views}
      {...rest}
    />
  );
};
